import React, { useId } from "react";
import ToolbarComponentBuilder from "../common/ToolbarComponentBuilder";
import { HBox, Icon, Label, Button } from "@/components";
import { isObject } from "@bsgp/lib-core";
import { Popover } from "@ui5/webcomponents-react";
import { useCodeEditorContext } from "@/contexts/codeEditorContext";

const OptionCustomBuilder = props => {
  const { ceId } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const {
    CustomOptions,
    selectedOptions,
    handleSelectOpt
  } = useCodeEditorContext();
  const openerId = useId();

  return (
    <>
      <Button id={openerId} onClick={() => setIsOpen(!isOpen)}>
        <HBox style={{ gap: "0.375rem" }}>
          에디터 커스텀 옵션
          <Icon name="sap-icon://navigation-down-arrow" />
        </HBox>
      </Button>
      <Popover
        opener={openerId}
        open={isOpen}
        placementType="Bottom"
        hideArrow={true}
        onAfterClose={() => setIsOpen(false)}
      >
        {CustomOptions.map((btn, index) => {
          const { name, label, options } = btn;
          return (
            <div
              key={`${ceId}_btn_${name}`}
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
                gap: "0.625rem"
              }}
            >
              <Label key={`${ceId}_label_${name}`}>{label}</Label>
              <ToolbarComponentBuilder
                type="Select"
                key={`${ceId}_btn_${name}`}
                value={selectedOptions[name]}
                properties={{
                  onChange: event => {
                    const value = event.target.value;
                    handleSelectOpt({ name, value });
                  }
                }}
                items={{
                  list: options.map(opt => {
                    if (isObject(opt)) {
                      return { key: opt.value, text: opt.label };
                    } else {
                      return { key: opt, text: opt };
                    }
                  })
                }}
                refineEventHookName="none"
              />
            </div>
          );
        })}
      </Popover>
    </>
  );
};

export default OptionCustomBuilder;

import React, { memo } from "react";
import DialogV2Builder from "./DialogV2Builder";
import { IDialogData } from "@/types/dialog";
import BaseIterator from "../common/BaseIterator";

interface IBuildDialogProps {
  renderData: {
    [key: string]: IDialogData;
  };
}

const DialogV2BuildIterator = (props: IBuildDialogProps) => {
  const { renderData } = props;
  const dialogNameList = Object.keys(renderData);

  return (
    <BaseIterator
      items={Object.values(renderData)}
      itemNameList={dialogNameList}
      wrapItems={false}
    >
      {(item, key) => (
        <DialogV2Builder key={`dialog-${key}`} {...item} dialogId={key} />
      )}
    </BaseIterator>
  );
};

export default memo(DialogV2BuildIterator);

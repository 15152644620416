// m.IconTabBar in openui5

import React from "react";
import { TabContainer, TabContainerPropTypes } from "@ui5/webcomponents-react";

const FtTabContainer = (props: TabContainerPropTypes) => {
  const { style, ...rest } = props;
  return <TabContainer {...rest} style={{ height: "initial", ...style }} />;
};
export default FtTabContainer;

const routes = [
  {
    path: "/resetpassword",
    component: () => import("components/ResetPassword"),
    checkAuth: false
  },
  {
    path: "/bill",
    component: () => import("@bsgp/jeilmedical")
  },
  {
    path: "/cipi",
    component: () => import("@bsgp/jeilmedical")
  },
  {
    path: "/task",
    component: () => import("@bsgp/jeilmedical")
  },
  {
    path: "/consignmentfillup",
    component: () => import("@bsgp/jeilmedical")
  },
  {
    path: "/arledger",
    component: () => import("@bsgp/jeilmedical")
  },
  {
    path: "/switchsystem",
    component: () => import("@bsgp/switch-system"),
    checkAuth: false
  },
  {
    path: "/qsdsi/:qsAlias",
    component: () => import("@bsgp/quicksight-dashboard"),
    additionalProps: {
      // qsConfig: {
      //   id: "InterfaceLog",
      //   type: "EmbedDashboard"
      // }
    }
  },
  {
    path: "/purchaseordercreation",
    component: () => import("@bsgp/byd-in-po-bundle")
  },
  {
    path: "/purchaseorderitems",
    component: () => import("@bsgp/byd-srm")
  },
  {
    path: "/prodconfirm",
    component: () =>
      import("components/production_confirm/ProductionOrderNumber")
  },
  {
    path: "/manageadir",
    component: () => import("components/manage_adir/PartnersList")
  },
  {
    path: "/managepaths",
    component: () => import("components/manage_adir/PathList")
  },
  {
    path: "/managepaths/path/create",
    component: () => import("components/manage_adir/PathDetail"),
    additionalProps: { mode: "create" }
  },
  {
    path: "/managepaths/path/:pathID",
    component: () => import("components/manage_adir/PathDetail"),
    additionalProps: { mode: "view" }
  },
  {
    path: "/managepaths/path/:pathID/edit",
    component: () => import("components/manage_adir/PathDetail"),
    additionalProps: { mode: "edit" }
  },
  {
    exact: false,
    path: "/manageroles",
    component: () => import("components/manage_adir/Role")
  },
  {
    path: "/manageadir/partner/create",
    component: () => import("components/manage_adir/PartnerDetail"),
    additionalProps: { mode: "create" }
  },
  {
    path: "/manageadir/partner/:partnerID",
    component: () => import("components/manage_adir/PartnerDetail"),
    additionalProps: { mode: "view" }
  },
  {
    path: "/manageadir/partner/:partnerID/edit",
    component: () => import("components/manage_adir/PartnerDetail"),
    additionalProps: { mode: "edit" }
  },
  {
    path: "/manageadir/partner/:partnerID/system/create",
    component: () => import("components/manage_adir/SystemDetail"),
    additionalProps: { mode: "create" }
  },
  {
    path: "/manageadir/partner/:partnerID/system/:systemID",
    component: () => import("components/manage_adir/SystemDetail"),
    additionalProps: { mode: "view" }
  },
  {
    path: "/manageadir/partner/:partnerID/system/:systemID/edit",
    component: () => import("components/manage_adir/SystemDetail"),
    additionalProps: { mode: "edit" }
  },
  {
    path: "/manageadir/partner/:partnerID/system/:systemID/user/create",
    component: () => import("components/manage_adir/UserDetail"),
    additionalProps: { mode: "create" }
  },
  {
    path: "/manageadir/partner/:partnerID/system/:systemID/user/:userID",
    component: () => import("components/manage_adir/UserDetail"),
    additionalProps: { mode: "view" }
  },
  {
    path: "/manageadir/partner/:partnerID/system/:systemID/user/:userID/edit",
    component: () => import("components/manage_adir/UserDetail"),
    additionalProps: { mode: "edit" }
  },
  {
    path: "/configuration",
    component: () => import("components/manage_adir/Configuration"),
    exact: false
  },
  {
    path: "/prodconfirm/order/:orderID",
    component: () => import("components/production_confirm/TaskList")
  },
  {
    path: "/prodconfirm/order/:orderID/task/:taskID",
    component: () => import("components/production_confirm/TaskDetailReact")
  },
  {
    path: "/productionschedule",
    component: () => import("components/production_plan/ProductionSchedule")
  },
  {
    exact: false,
    path: "/prm",
    component: () => import("@bsgp/dna-prm")
  },
  {
    exact: false,
    path: "/lghnh",
    component: () => import("@bsgp/lghnh-pop")
  },
  {
    exact: false,
    path: "/gc",
    component: () => import("@bsgp/gc-pm")
  },
  {
    exact: false,
    path: "/etl",
    component: () => import("@bsgp/se11")
  },
  {
    exact: false,
    path: "/ccs",
    component: () => import("@bsgp/spark")
  },
  {
    exact: false,
    path: "/srm",
    component: () => import("@bsgp/srm")
  },
  {
    exact: false,
    path: "/byd/srm",
    component: () => import("@bsgp/byd-srm")
  },
  {
    exact: false,
    path: "/dir",
    component: () => import("@bsgp/directory")
  },
  {
    exact: false,
    path: "/users",
    component: () => import("@bsgp/users")
  },
  {
    exact: false,
    path: "/helpeditor",
    component: () => import("@bsgp/help-editor")
  },
  {
    exact: false,
    path: "/jeilmedical",
    component: () => import("@bsgp/jeilmedical")
  },
  {
    exact: false,
    path: "/cms",
    component: () => import("@bsgp/cms")
  },
  {
    exact: false,
    path: "/qualityresults",
    component: () => import("@bsgp/cntech-qualityresults")
  },
  {
    exact: false,
    path: "/outboundTask",
    component: () => import("@bsgp/jeilmedical")
  },
  {
    exact: false,
    path: "/apihub",
    component: () => import("@bsgp/api-hub")
  },
  {
    exact: false,
    path: "/sso-metadata",
    component: () => import("@bsgp/sso-metadata")
  }
];
export default routes;

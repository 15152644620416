import React from "react";
import clsx from "clsx";
import NodeEditorBuilder from "./Builder";
import { INodeEditorBuilderProps } from "@/types/nodeEditor";
import { NodeEditorContextProvider } from "@/contexts/nodeEditorContext";
import BaseIterator from "../common/BaseIterator";

interface INodeEditorIteratorProps {
  renderData: INodeEditorBuilderProps[];
}

function NodeEditorIterator(props: INodeEditorIteratorProps) {
  const { renderData } = props;

  const nodeEditorNameList = renderData.map(
    (ne, index) => ne.name || `node-editor-${index}`
  );

  return (
    <BaseIterator
      items={renderData}
      itemNameList={nodeEditorNameList}
      wrapItems={renderData.length > 1}
      contextProvider={NodeEditorContextProvider}
      tabContainerProps={{
        className: clsx("noBottomLine", "ft-height-100-for-codeeditor"),
        style: { height: "100%" }
      }}
    >
      {(item, key) => <NodeEditorBuilder key={key} {...item} />}
    </BaseIterator>
  );
}

export default NodeEditorIterator;

(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@bsgp/form-and-table/common"), require("@bsgp/form-and-table/fieldComponent"), require("@bsgp/form-and-table/lib/functions"), require("@bsgp/lib-api/store"), require("@bsgp/lib-core"), require("@bsgp/lib-hoc/src/modules"), require("@lexical/react/LexicalComposer"), require("@lexical/react/LexicalContentEditable"), require("@lexical/react/LexicalErrorBoundary"), require("@lexical/react/LexicalHistoryPlugin"), require("@lexical/react/LexicalRichTextPlugin"), require("@lexical/selection"), require("@lexical/utils"), require("@monaco-editor/react"), require("@ui5/webcomponents-fiori/dist/SortItem"), require("@ui5/webcomponents-react"), require("@ui5/webcomponents/dist/features/InputElementsFormSupport.js"), require("@ui5/webcomponents/dist/features/InputSuggestions.js"), require("clsx"), require("lexical"), require("path"), require("react"), require("react-dom"), require("react-jss"), require("react-paginate"), require("reactflow"), require("reactflow/dist/style.css"), require("title"));
	else if(typeof define === 'function' && define.amd)
		define(["@bsgp/form-and-table/common", "@bsgp/form-and-table/fieldComponent", "@bsgp/form-and-table/lib/functions", "@bsgp/lib-api/store", "@bsgp/lib-core", "@bsgp/lib-hoc/src/modules", "@lexical/react/LexicalComposer", "@lexical/react/LexicalContentEditable", "@lexical/react/LexicalErrorBoundary", "@lexical/react/LexicalHistoryPlugin", "@lexical/react/LexicalRichTextPlugin", "@lexical/selection", "@lexical/utils", "@monaco-editor/react", "@ui5/webcomponents-fiori/dist/SortItem", "@ui5/webcomponents-react", "@ui5/webcomponents/dist/features/InputElementsFormSupport.js", "@ui5/webcomponents/dist/features/InputSuggestions.js", "clsx", "lexical", "path", "react", "react-dom", "react-jss", "react-paginate", "reactflow", "reactflow/dist/style.css", "title"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("@bsgp/form-and-table/common"), require("@bsgp/form-and-table/fieldComponent"), require("@bsgp/form-and-table/lib/functions"), require("@bsgp/lib-api/store"), require("@bsgp/lib-core"), require("@bsgp/lib-hoc/src/modules"), require("@lexical/react/LexicalComposer"), require("@lexical/react/LexicalContentEditable"), require("@lexical/react/LexicalErrorBoundary"), require("@lexical/react/LexicalHistoryPlugin"), require("@lexical/react/LexicalRichTextPlugin"), require("@lexical/selection"), require("@lexical/utils"), require("@monaco-editor/react"), require("@ui5/webcomponents-fiori/dist/SortItem"), require("@ui5/webcomponents-react"), require("@ui5/webcomponents/dist/features/InputElementsFormSupport.js"), require("@ui5/webcomponents/dist/features/InputSuggestions.js"), require("clsx"), require("lexical"), require("path"), require("react"), require("react-dom"), require("react-jss"), require("react-paginate"), require("reactflow"), require("reactflow/dist/style.css"), require("title")) : factory(root["@bsgp/form-and-table/common"], root["@bsgp/form-and-table/fieldComponent"], root["@bsgp/form-and-table/lib/functions"], root["@bsgp/lib-api/store"], root["@bsgp/lib-core"], root["@bsgp/lib-hoc/src/modules"], root["@lexical/react/LexicalComposer"], root["@lexical/react/LexicalContentEditable"], root["@lexical/react/LexicalErrorBoundary"], root["@lexical/react/LexicalHistoryPlugin"], root["@lexical/react/LexicalRichTextPlugin"], root["@lexical/selection"], root["@lexical/utils"], root["@monaco-editor/react"], root["@ui5/webcomponents-fiori/dist/SortItem"], root["@ui5/webcomponents-react"], root["@ui5/webcomponents/dist/features/InputElementsFormSupport.js"], root["@ui5/webcomponents/dist/features/InputSuggestions.js"], root["clsx"], root["lexical"], root["path"], root["react"], root["react-dom"], root["react-jss"], root["react-paginate"], root["reactflow"], root["reactflow/dist/style.css"], root["title"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(this, (__WEBPACK_EXTERNAL_MODULE__178__, __WEBPACK_EXTERNAL_MODULE__156__, __WEBPACK_EXTERNAL_MODULE__940__, __WEBPACK_EXTERNAL_MODULE__171__, __WEBPACK_EXTERNAL_MODULE__228__, __WEBPACK_EXTERNAL_MODULE__368__, __WEBPACK_EXTERNAL_MODULE__505__, __WEBPACK_EXTERNAL_MODULE__840__, __WEBPACK_EXTERNAL_MODULE__65__, __WEBPACK_EXTERNAL_MODULE__198__, __WEBPACK_EXTERNAL_MODULE__361__, __WEBPACK_EXTERNAL_MODULE__645__, __WEBPACK_EXTERNAL_MODULE__78__, __WEBPACK_EXTERNAL_MODULE__628__, __WEBPACK_EXTERNAL_MODULE__851__, __WEBPACK_EXTERNAL_MODULE__650__, __WEBPACK_EXTERNAL_MODULE__269__, __WEBPACK_EXTERNAL_MODULE__460__, __WEBPACK_EXTERNAL_MODULE__88__, __WEBPACK_EXTERNAL_MODULE__664__, __WEBPACK_EXTERNAL_MODULE__911__, __WEBPACK_EXTERNAL_MODULE__155__, __WEBPACK_EXTERNAL_MODULE__514__, __WEBPACK_EXTERNAL_MODULE__258__, __WEBPACK_EXTERNAL_MODULE__87__, __WEBPACK_EXTERNAL_MODULE__307__, __WEBPACK_EXTERNAL_MODULE__247__, __WEBPACK_EXTERNAL_MODULE__434__) => {
return 
import React from "react";
import TableBuilder from "./TableBuilder";
import { ConstructTableDataType, ITableData } from "@/types/table";
import BaseIterator from "../common/BaseIterator";
import { TabLayout } from "@ui5/webcomponents-react";

const TableIterator = ({
  wrapSingleTable,
  renderData
}: {
  wrapSingleTable: boolean;
  renderData: ConstructTableDataType;
}) => {
  const doWrapTables = renderData.length > 1 || wrapSingleTable === true;
  const hasIcon = getHasIcon(renderData);

  return (
    <BaseIterator
      items={renderData}
      itemNameList={renderData.map(tableObject => tableObject.name)}
      wrapItems={doWrapTables}
      tabContainerProps={{
        className: "ft-selected-icon-tab noBottomLine",
        tabLayout: hasIcon ? TabLayout.Standard : TabLayout.Inline
      }}
      tabProps={(tabObject: ITableData) => ({
        additionalText:
          tabObject.count === undefined
            ? undefined
            : tabObject.count.toString(),
        icon: tabObject.icon
      })}
    >
      {({ title, icon, ...rest }, key) => <TableBuilder key={key} {...rest} />}
    </BaseIterator>
  );
};

function getHasIcon(tableDataList) {
  return tableDataList.reduce((acc, tableObject) => {
    if (acc) {
      return acc;
    }
    if (tableObject.icon) {
      return true;
    }
    return false;
  }, false);
}

export default TableIterator;

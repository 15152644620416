import React from "react";
import { IDialogData } from "@/types/dialog";
import { Text, Toolbar, ToolbarSpacer } from "@/components";
import { ButtonDesign } from "@ui5/webcomponents-react";
import Button from "@/components/inputs/Button";
import Image from "@/components/inputs/Image";
import { useDialogHooks } from "@/hooks/dialog";

const DialogHeaderBuilder = (props: IDialogData) => {
  const {
    properties,
    title,
    beginButtonText,
    hideBeginButton,
    headerImageUrl
  } = props;

  const { getDialogEvent, handleCloseDialog } = useDialogHooks();
  const { onConfirm, onBeforeCancel } = getDialogEvent(props);
  const titleText = title || properties.title;

  return (
    <Toolbar className="sapUiSizeCompact">
      {!hideBeginButton && (
        <Button
          value={beginButtonText || "확인"}
          properties={{
            design: ButtonDesign.Emphasized,
            onClick: async event => {
              if (onConfirm) {
                const result = await onConfirm(event);
                if (result === false) {
                  return;
                }
              }
              handleCloseDialog(props);
            }
          }}
        />
      )}
      <Button
        value="닫기"
        properties={{
          design: ButtonDesign.Negative,
          onClick: async event => {
            if (onBeforeCancel) {
              const result = await onBeforeCancel(event);
              if (result === false) {
                return;
              }
            }

            handleCloseDialog(props);
          }
        }}
      />
      {titleText && <Text value={titleText} />}
      {headerImageUrl && <ToolbarSpacer />}
      {headerImageUrl && (
        <Image value={headerImageUrl} height="var(--sapElement_Height)" />
      )}
    </Toolbar>
  );
};

export default DialogHeaderBuilder;

import React, { CSSProperties } from "react";
import { IColumnData } from "@/types/table";
import ComponentBuilder from "@/builders/common/ComponentBuilder";

interface IColumnBuilderProps {
  data: Omit<IColumnData, "properties"> & {
    properties: IColumnData;
  };
  tableName: string;
  slot?: string;
}

const ColumnBuilder = (props: IColumnBuilderProps) => {
  const { data, tableName } = props;
  const { properties } = data;
  return (
    <ComponentBuilder
      slot="columns"
      name={`${tableName}_columns`}
      type="TableColumn"
      {...data}
    >
      <div
        style={
          {
            textAlign:
              (properties.hAlign?.toLowerCase() as CSSProperties) || "left"
          } as CSSProperties
        }
      >
        {data.text}
      </div>
    </ComponentBuilder>
  );
};

export default ColumnBuilder;

import React from "react";
import { ConstructFormDataType } from "@/types/form";
import FormBuilder from "./FormBuilder";
import BaseIterator from "../common/BaseIterator";

const FormIterator = ({
  renderData,
  wrapForms,
  ...options
}: {
  renderData: ConstructFormDataType;
  wrapForms?: boolean;
}) => {
  const doWrapForms = renderData.length > 1 && wrapForms;
  const itemNameList = renderData.map((formObject, index) => {
    return formObject.namedForm ? formObject.name : `form-${index}`;
  });

  return (
    <BaseIterator
      items={renderData}
      itemNameList={itemNameList}
      wrapItems={doWrapForms}
      tabContainerProps={{
        className: "ft-transition-for-hide",
        style: { flex: 0 },
        "data-ui5-compact-size": true
      }}
    >
      {({ onSelect, ...rest }, key) => (
        <FormBuilder formObject={rest} title={""} {...options} />
      )}
    </BaseIterator>
  );
};

export default FormIterator;

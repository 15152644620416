import React from "react";
import { IConstructorProps } from "@/types/common";

interface IBaseConstructorProps<T> extends IConstructorProps {
  dataKey: string;
  children: (renderData: any) => React.ReactNode;
  dataProcessor?: (data: any, option: { isReact: boolean }) => T;
  additionalCheck?: (data: any) => boolean;
  ContextProvider?: React.ComponentType<any>;
  additionalProps?: object;
}

function BaseConstructor<T>({
  data,
  dataKey,
  children,
  dataProcessor,
  additionalCheck = () => true,
  additionalProps = {}
}: IBaseConstructorProps<T>) {
  if (!data[dataKey] || !additionalCheck(data)) return null;

  const processedData = dataProcessor
    ? dataProcessor(data, {
        isReact: true
      })
    : data[dataKey];

  return children({ renderData: processedData, ...additionalProps });
}

export default BaseConstructor;

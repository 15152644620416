import React from "react";
import { ICodeEditor } from "@/types/common";
import clsx from "clsx";
import CodeEditorBuilder from "./Builder";
import { CodeEditorContextProvider } from "@/contexts/codeEditorContext";
import BaseIterator from "../common/BaseIterator";

interface ICodeEditorIteratorProps {
  renderData: { [key: string]: ICodeEditor };
}

function CodeEditorIterator(props: ICodeEditorIteratorProps) {
  const { renderData } = props;
  const itemKeys = Object.keys(renderData);
  const items = Object.values(renderData);
  const itemNameList = itemKeys.map((ceKey, index) => {
    return renderData[ceKey].title
      ? renderData[ceKey].title
      : `code-editor-${index}`;
  });

  return (
    <BaseIterator
      items={items}
      itemNameList={itemNameList}
      wrapItems={itemKeys.length > 1}
      contextProvider={CodeEditorContextProvider}
      tabContainerProps={{
        className: clsx("noBottomLine", "ft-height-100-for-codeeditor"),
        style: { height: "100%" }
      }}
    >
      {(item, key) => <CodeEditorBuilder {...item} ceId={key} />}
    </BaseIterator>
  );
}

export default CodeEditorIterator;

import { useEffect } from "react";
// prettier-ignore
import { useLexicalComposerContext } from
"@lexical/react/LexicalComposerContext";
import { EditorState } from "lexical";
import { BLUR_COMMAND, COMMAND_PRIORITY_EDITOR } from "lexical";

interface OnBlurPluginProps {
  onBlur: (editorState: EditorState) => void;
}

export function OnBlurPlugin({ onBlur }: OnBlurPluginProps): null {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerCommand(
      BLUR_COMMAND,
      (payload, editor) => {
        const editorState = editor.getEditorState();
        onBlur(editorState);
        return false;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [editor, onBlur]);

  return null;
}

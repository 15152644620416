import React from "react";
import { constructFooterData } from "@bsgp/form-and-table/common";
import { ICommonProps } from "@/types/common";
import FooterBuilder from "@/builders/footer/FooterBuilder";
import BaseConstructor from "../common/BaseConstructor";

const FooterConstructor = (props: ICommonProps) => (
  <BaseConstructor
    {...props}
    dataKey="footer"
    dataProcessor={data => {
      return constructFooterData(data.footer, { isReact: true });
    }}
    additionalProps={{
      showToggleBtn: !!(props.data.form && props.data.table),
      stickyFooter: props.data.stickyFooter
    }}
  >
    {renderProps => <FooterBuilder {...renderProps} />}
  </BaseConstructor>
);

export default FooterConstructor;

import React from "react";
import { MultiComboBox, MultiComboBoxItem } from "@ui5/webcomponents-react";
import { isArray } from "@bsgp/lib-core";
import {
  IComboBoxOption,
  IMultiComboBoxProps,
  IMultiComboBoxOption
} from "@/types/component";

function FtMultiComboBox(props: IMultiComboBoxProps) {
  const { items, properties = {}, value } = props;

  const values = (isArray(value) ? value : [value]) as string[];

  const options: IMultiComboBoxOption[] = items.list.map(
    (option: IComboBoxOption) => ({
      ...option,
      selected:
        values.find(value => value === option.key) === undefined ? false : true
    })
  );

  return (
    <MultiComboBox allowCustomValues={true} {...properties}>
      {options.map(opt => {
        return <MultiComboBoxItem {...opt} />;
      })}
    </MultiComboBox>
  );
}

export default FtMultiComboBox;

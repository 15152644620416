import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  Link,
  Menu,
  MenuItem,
  LinkDesign,
  Icon
} from "@ui5/webcomponents-react";
import { createUseStyles } from "react-jss";

import { setCurrentLang } from "actions/user";

const useStyles = createUseStyles({
  languageIcon: {
    "&": {
      verticalAlign: "middle"
    }
  },
  languageText: {
    "&": {
      marginRight: "0.25rem"
    }
  }
});

const languages = {
  en: "English",
  ko: "한국어"
};

function Languages(props) {
  const classes = useStyles();

  const { currentUser, dispatch } = props;

  // if (!currentUser.is_authenticated) {
  //   return false;
  // }
  console.log("currentUser.lang:", currentUser.lang);

  return (
    <div slot={props.slot}>
      <Link
        id={"openLanguagesBtn"}
        design={LinkDesign.Emphasized}
        icon={"navigation-down-arrow"}
        className={classes.languageText}
        onClick={() => {
          // setMenuIsOpen(!menuIsOpen);
          document
            .querySelector("#languagesMenu")
            .showAt(document.querySelector("#openLanguagesBtn"));
        }}
      >
        <Icon name="world" className={classes.languageIcon}></Icon>{" "}
        {languages[currentUser.lang]}
      </Link>
      <Menu
        id={"languagesMenu"}
        onItemClick={event => {
          const { setLangTo } = event.detail.item.dataset;
          if (setLangTo) {
            dispatch(setCurrentLang(setLangTo));
          }
        }}
      >
        <MenuItem text={"English"} data-set-lang-to={"en"} />
        <MenuItem text={"한국어"} data-set-lang-to={"ko"} />
      </Menu>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.currentUser
  };
}

export default withRouter(connect(mapStateToProps)(Languages));

import React, { useState, useId } from "react";
import {
  Button,
  PopoverHorizontalAlign,
  PopoverPlacementType,
  PopoverVerticalAlign
} from "@ui5/webcomponents-react";
import { Menu } from "@/components";
import { IMenuButtonProps } from "@/types/component";
import ComponentBuilder from "@/builders/common/ComponentBuilder";
import { ButtonTypeToDesignMap } from "@/maps/legacyMap";
import Icon from "@/components/Icon";
import { HBox } from "@/components";

function MenuButton(props: IMenuButtonProps) {
  const {
    style,
    value,
    items,
    properties = {},
    refineEventHookName,
    className,
    ...restProps
  } = props;

  const [selectedIdx, setSelectedIdx] = useState(null);
  const [event, setEvent] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => {
    setIsOpen(isOpen => !isOpen);
  };
  const opener = useId();

  return (
    <>
      <Button
        id={opener}
        // icon={""}
        // iconEnd={true}
        design={ButtonTypeToDesignMap[properties.type] || "Default"}
        {...properties}
        style={{ ...style }}
        className={className}
        onClick={handleOpen}
      >
        <HBox style={{ gap: "0.375rem" }}>
          {value}
          <Icon name="sap-icon://navigation-down-arrow" />
        </HBox>
      </Button>
      <Menu
        opener={opener}
        open={isOpen}
        onAfterClose={() => setIsOpen(false)}
        onItemClick={event => {
          const selectedItem = event.detail.item;
          const selectedIdx = selectedItem.dataset.idx;

          setSelectedIdx(selectedIdx);
          setEvent(event);
          setIsOpen(false);
        }}
        horizontalAlign={PopoverHorizontalAlign.Center}
        placementType={PopoverPlacementType.Bottom}
        verticalAlign={PopoverVerticalAlign.Center}
      >
        {Object.entries(items).map(([key, btn], index) => {
          const curIndex = getIndex(index);
          return (
            <ComponentBuilder
              {...restProps}
              key={key}
              type="MenuItem"
              data-idx={curIndex}
              getIndex={getIndex}
              selectedIdx={selectedIdx}
              handleSelectedIdx={setSelectedIdx}
              event={event}
              setEvent={setEvent}
              refineEventHookName={refineEventHookName}
              {...btn}
            />
          );
        })}
      </Menu>
    </>
  );
}

const getIndex = (index: number, path?: string) => {
  if (path !== undefined) {
    return `${path}.${index}`;
  } else {
    return `${index}`;
  }
};

export default MenuButton;

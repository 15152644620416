import React from "react";
import { FlexBox } from "@/components";
import { ICodeEditor } from "@/types/common";
import { isObject } from "@bsgp/lib-core";
import EditorToolbarBuilder from "./EditorToolbarBuilder";
import CodeEditor from "@/components/editors/CodeEditor";
import { useRefineEventForCodeEditor } from "@/hooks/refineProps";
import { useCodeEditorContext } from "@/contexts/codeEditorContext";

function CodeEditorBuilder(props: ICodeEditor & { ceId: string }) {
  const {
    buttons,
    value,
    ceId,
    properties,
    options = {},
    showCustomOption
  } = props;
  const {
    selectedOptions,
    isFullScreen,
    handleFullScreen
  } = useCodeEditorContext();
  const refinedProperties = useRefineEventForCodeEditor(
    properties,
    options.doNotRefine
  );

  const codeEditorButtons = Array.isArray(buttons)
    ? buttons
    : isObject(buttons)
    ? [buttons]
    : [];

  return (
    <>
      <EditorToolbarBuilder
        buttons={codeEditorButtons}
        ceId={ceId}
        isFullScreen={isFullScreen}
        handleFullScreen={handleFullScreen}
        showCustomOption={showCustomOption}
      />
      <FlexBox fitContainer={true}>
        <CodeEditor
          properties={refinedProperties}
          value={value}
          options={{
            tabSize: 2,
            insertSpaces: true,
            ...selectedOptions
          }}
        />
      </FlexBox>
    </>
  );
}

export default CodeEditorBuilder;

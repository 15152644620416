import React, { useCallback, useEffect } from "react";
import {
  refSetterOpenDialogIds,
  refSetterValueHelpV2Event
} from "@/contexts/dialogContext";
import { IValueHelpV2Event, IValueHelpV2EventVal } from "@/types/dialog";
import { refineEventForDialog } from "@/lib/refineEvent";
import { propsRef } from "@/ref";
import { IValueHelpV2 } from "@/types/component";
import { IDialogData } from "@/types/dialog";
import { useDialogContext } from "@/contexts/dialogContext";
import { IInputProps } from "@/types/component";
import Icon from "@/components/Icon";
import { createUseStyles } from "react-jss";
import { isFunction } from "@bsgp/lib-core";
import { ValueOf } from "@/types/utility";

export const useDialogHooks = () => {
  const { openDialogIds, valueHelpV2Event, selectedRows } = useDialogContext();
  const fn = propsRef.current.fn;

  const manuallyOpenDialog = useCallback(
    dialogId => {
      if (openDialogIds.includes(dialogId)) return;
      refSetterOpenDialogIds.current(dialogIds => [...dialogIds, dialogId]);
    },
    [openDialogIds, refSetterOpenDialogIds]
  );

  const manuallyCloseDialog = useCallback(
    dialogId => {
      if (openDialogIds.length === 0) return;
      refSetterOpenDialogIds.current(dialogIds =>
        dialogIds.filter(id => id !== dialogId)
      );
    },
    [openDialogIds, refSetterOpenDialogIds]
  );

  const getIsOpen = useCallback(
    ({ isOpenFromProps, dialogId }) => {
      if (isOpenFromProps) return isOpenFromProps;
      return openDialogIds.includes(dialogId);
    },
    [openDialogIds]
  );
  const getDialogEvent = useCallback(
    (props: IDialogData) => {
      const { dialogId } = props;
      const valueHelpEvent = valueHelpV2Event[dialogId];
      const valueHelpEvents = !!valueHelpEvent
        ? Object.entries(valueHelpEvent).reduce(
            (
              acc,
              [fnKey, fnValue]: [string, ValueOf<IValueHelpV2EventVal>]
            ) => {
              if (isFunction(fnValue)) {
                acc[fnKey] = event =>
                  fnValue({ ...event, items: selectedRows });
                return acc;
              }
              return acc;
            },
            {}
          )
        : {};

      return {
        ...refineEventForDialog(props, fn, selectedRows),
        ...valueHelpEvents
      };
    },
    [valueHelpV2Event, fn, selectedRows]
  );

  const handleCloseDialog = useCallback(
    props => {
      const dialogEvent: IValueHelpV2 = getDialogEvent(props);
      dialogEvent.onClose && dialogEvent.onClose();
      manuallyCloseDialog(props.dialogId);
    },
    [getDialogEvent, manuallyCloseDialog]
  );

  return {
    registerValueHelpV2Event,
    manuallyOpenDialog,
    manuallyCloseDialog,
    getIsOpen,
    getDialogEvent,
    handleCloseDialog
  };
};

export const useValueHelpV2 = (props: IInputProps) => {
  const { valueHelpV2, valueHelpOnly } = props.properties;
  const { registerValueHelpV2Event, manuallyOpenDialog } = useDialogHooks();
  const classes = useStyles();

  useEffect(() => {
    registerValueHelpV2Event(valueHelpV2);
  }, []);

  if (!valueHelpV2) return {};

  const { dialogId } = valueHelpV2;

  const handleOpenDialog = () => {
    manuallyOpenDialog(dialogId);
  };

  return {
    icon: !!valueHelpV2 ? (
      <Icon interactive={true} name="value-help" onClick={handleOpenDialog} />
    ) : null,
    valueHelpProps: valueHelpOnly
      ? { onClick: handleOpenDialog, readonly: true }
      : {},
    valueHelpClass: valueHelpOnly ? classes.input : ""
  };
};

const useStyles = createUseStyles({
  input: {
    cursor: "pointer",
    backgroundColor: "white"
  }
});

const registerValueHelpV2Event = (valueHelpV2: IValueHelpV2) => {
  if (!valueHelpV2) return;
  const { dialogId, ...functions } = valueHelpV2;

  refSetterValueHelpV2Event.current((currentEvent: IValueHelpV2Event) => {
    return {
      ...currentEvent,
      [dialogId]: functions
    };
  });
};

import React from "react";
import DialogV2BuildIterator from "./DialogV2BuildIterator";
import { ICommonProps } from "@/types/common";
import { DialogContextProvider } from "@/contexts/dialogContext";
import BaseConstructor from "../common/BaseConstructor";

const DialogV2Constructor = (props: ICommonProps) => (
  <BaseConstructor {...props} dataKey="dialogV2">
    {renderProps => (
      <DialogContextProvider>
        <DialogV2BuildIterator {...renderProps} />
      </DialogContextProvider>
    )}
  </BaseConstructor>
);

export default DialogV2Constructor;

import React from "react";
import { isArray } from "@bsgp/lib-core";
import { ICommonProps } from "@/types/common";
import HistoryBuilder from "./Builder";
import BaseConstructor from "../common/BaseConstructor";

const HistoryConstructor = (props: ICommonProps) => (
  <BaseConstructor
    {...props}
    dataKey="historyLinks"
    dataProcessor={data => {
      return isArray(data.historyLinks) ? data.historyLinks : [];
    }}
    additionalProps={{
      history: props.history,
      title: props.title
    }}
  >
    {renderProps => <HistoryBuilder {...renderProps} />}
  </BaseConstructor>
);

export default HistoryConstructor;

import React from "react";
import { IColumnData } from "@/types/table";
import { tryit } from "@bsgp/lib-core";
import { isArray } from "@bsgp/lib-core";
import ComponentBuilder from "@/builders/common/ComponentBuilder";
import { HBox, VBox, FlexBox } from "@/components";
import { FlexBoxJustifyContent } from "@ui5/webcomponents-react";
import { IComponentBuilderProps } from "@/types/common";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles<
  "wrapLayout" | "wrappingBox",
  { column: IColumnData }
>({
  wrapLayout: {
    width: ({ column }) => column.width,
    minWidth: ({ column }) => column.minWidth,
    maxWidth: ({ column }) => column.maxWidth
  },
  wrappingBox: {
    width: ({ column }) => column.width
  }
});

interface ICellBuilderProps {
  column: IColumnData;
  value: any;
  cellName: string;
  rowData: object;
}

function CellBuilder(props: ICellBuilderProps) {
  const { column, value, cellName, rowData } = props;
  const classes = useStyles({ column });
  if (isArray(column.component)) {
    const colComponents = column.component as IComponentBuilderProps[];
    const WrappingBox = column.vertical ? VBox : HBox;
    return (
      <FlexBox
        name={"wrapLayout-" + cellName}
        // baseSize= {"100%"}
        className={classes.wrapLayout}
      >
        <WrappingBox
          name={cellName}
          className={classes.wrappingBox}
          alignItems="Center"
        >
          {colComponents.map((comp, idx) => {
            return (
              <ComponentBuilder
                key={[cellName, comp.name, idx].filter(Boolean).join("-")}
                name={[cellName, comp.name, idx].filter(Boolean).join("-")}
                conv={column.conv}
                {...comp}
                value={value}
                rowData={rowData}
                refineEventHookName={"useRefineEventForTableCell"}
              />
            );
          })}
        </WrappingBox>
      </FlexBox>
    );
  }

  const colComponent = column.component as IComponentBuilderProps;
  if (
    tryit(() => colComponent.type) === "ObjectNumber" &&
    column.hideOnInitial === true
  ) {
    return (
      <HBox name={cellName} justifyContent={FlexBoxJustifyContent.End}>
        <ComponentBuilder
          {...colComponent}
          name={cellName}
          properties={{
            ...colComponent.properties,
            visible: value === "0.00" ? false : true
          }}
          value={value}
          rowData={rowData}
          refineEventHookName={"useRefineEventForTableCell"}
        />
        <ComponentBuilder
          {...colComponent}
          name={cellName}
          type={"Text"}
          properties={{
            visible: value === "0.00" ? true : false
          }}
          value={value}
          rowData={rowData}
          refineEventHookName={"useRefineEventForTableCell"}
        />
      </HBox>
    );
  }

  return (
    <ComponentBuilder
      name={cellName}
      conv={column.conv}
      {...colComponent}
      properties={{
        ...column.properties,
        ...(colComponent && colComponent.properties)
      }}
      value={value}
      rowData={rowData}
      refineEventHookName={"useRefineEventForTableCell"}
    />
  );
}

export default CellBuilder;

import React from "react";
import { ComboBox, ComboBoxItem } from "@ui5/webcomponents-react";
import { IComboBoxProps, IMultiComboBoxProps } from "@/types/component";
import { MultiComboBox } from "..";

const FtComboBox = (props: IComboBoxProps | IMultiComboBoxProps) => {
  if (props.multiple) {
    return <MultiComboBox {...(props as IMultiComboBoxProps)} />;
  }

  const { items, properties, style, value } = props as IComboBoxProps;

  return (
    <ComboBox {...properties} style={style} value={value}>
      {items.list.map(item => (
        <ComboBoxItem text={item.text} key={item.key} />
      ))}
    </ComboBox>
  );
};

export default FtComboBox;

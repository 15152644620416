import React, { ReactNode } from "react";
import { Form } from "@/components";
import clsx from "clsx";
import { IFormObject } from "@/types/form";
import FormGroupBuilder from "./FormGroupBuilder";
import { createUseStyles } from "react-jss";

interface IFormBuilderProps {
  formObject: Omit<IFormObject, "onSelect">;
  title?: string;
}

function FormBuilder({
  formObject,
  title,
  ...options
}: IFormBuilderProps): ReactNode {
  const formName = formObject.name;
  const classes = useStyles();
  const sizeV2 = formObject.sizeV2 === true;

  const layout = calculateFormLayout(formObject);
  return (
    <div>
      <Form
        name={formName}
        key={formName}
        title={title}
        className={clsx([...getStyleClassNames(formObject), classes.form])}
        style={{
          flex: 0,
          padding: "1rem 0.75rem",
          width: sizeV2 || formObject.containers.length > 1 ? "100%" : "auto"
        }}
        {...layout}
        {...options}
        {...(formObject.properties || {})}
        {...formObject}
      >
        <FormGroupBuilder
          formObject={formObject}
          formName={formName}
          sizeV2={sizeV2}
        />
      </Form>
    </div>
  );
}

const calculateFormLayout = formObject => {
  const defaultLabelSpan = formObject.labelSpanM || 2;
  if (formObject.sizeV2) {
    return {
      columnsL: 3,
      labelSpanL: 4,
      columnsXL: 4
    };
  }
  if (formObject.containers.length === 1) {
    return {
      columnsS: 1,
      columnsM: 1,
      columnsL: 1,
      columnsXL: 1,
      labelSpanS: defaultLabelSpan,
      labelSpanM: defaultLabelSpan,
      labelSpanL: defaultLabelSpan,
      labelSpanXL: defaultLabelSpan
    };
  }
  return {};
};

const getStyleClassNames = formObject => {
  const classList = [
    "ft-transition-for-hide",
    "ft-label-word-break",
    "fields-relative-position",
    "ft-form-elements-overflow-visible"
  ];
  // const range = window.sap.ui.Device.media.getCurrentRange(
  //   window.sap.ui.Device.media.RANGESETS.SAP_STANDARD
  // );
  // onResize(range, comp);

  if (formObject.compactSize === true) {
    classList.push("sapUiSizeCompact");
  }

  if (formObject.tightContainers === true) {
    classList.push("container-fit-content");
  }

  return classList;
};

export default FormBuilder;

const useStyles = createUseStyles({
  form: {
    "& .sapMText": {
      lineHeight: "1rem",
      paddingTop: "0.5rem",
      marginTop: 0,
      minHeight: "1rem",
      paddingBottom: "0.5rem"
    }
  }
});

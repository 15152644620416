import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { apiHub } from "@bsgp/lib-api";
import { formTable, ft } from "@bsgp/form-and-table";
import { FormTable as FormTableReact } from "@bsgp/ft-react";

import { addError, clearNotice } from "actions/ui5";

function NoticeDialog(props) {
  const { noticeList, ignoreNotice, dispatch, currentUser } = props;
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [ignore, setIgnore] = useState(ignoreNotice);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (noticeList.length > 0) {
      setDialogIsOpen(true);
    }
  }, [noticeList.length, ignore]);

  const handleClose = () => {
    setDialogIsOpen(false);
    dispatch(clearNotice());
  };

  const handleShowToday = () => {
    const { systemID, partnerID, id } = currentUser;
    const sys_id = [systemID, partnerID].join("@");
    const params = {
      InterfaceId: "IF-NT-015",
      Function: { UserId: id },
      Data: { user_id: id, sys_id, ignore_indicator: !ignore }
    };
    apiHub.post("/g/notice", params, {
      afterSucceed: ({ E_STATUS, E_MESSAGE, ignoreIndicator }) => {
        dispatch(addError({ message: E_MESSAGE, type: E_STATUS }));
        setIgnore(ignoreIndicator);
      }
    });
  };

  const handleDialogIndex = (move = "home") => {
    if (move === "next") {
      if (currentIndex + 1 < noticeList.length)
        setCurrentIndex(currentIndex + 1);
      else dispatch(addError("마지막 페이지입니다."));
    } else if (move === "back") {
      if (currentIndex - 1 >= 0) setCurrentIndex(currentIndex - 1);
      else dispatch(addError("첫 페이지입니다."));
    } else if (move === "home") {
      if (currentIndex !== 0) setCurrentIndex(0);
      else dispatch(addError("첫번째 페이지입니다."));
    }
  };

  // console.log("NoticeDialog props::::", props);
  const logoUrl = (currentUser.system && currentUser.system.logo) || "";
  const currentNotice = noticeList[currentIndex] || {};

  const pageInfo = [
    `전체 페이지: ${noticeList.length}`,
    `현재: ${currentIndex + 1}`
  ].join(" / ");

  const dialogBuilder = formTable()
    .addForm("noticeForm", {
      sizeV2: true,
      compactSize: true,
      columnsXL: 1,
      columnsL: 1,
      columnsM: 1,
      labelSpanM: 2,
      labelSpanL: 1,
      labelSpanXL: 1
    })
    .addContainer("noticeContainer")
    .addField("noticeInfo", {
      label: "",
      value: [pageInfo, "처음으로", "이전", "다음", ignore],
      component: [
        ft.Text(),
        ft.Button("home", {
          properties: {
            icon: "sap-icon://home",
            type: window.sap.m.ButtonType.Emphasized
          },
          onPress: () => () => handleDialogIndex("home")
        }),
        ft.Button("back", {
          properties: { icon: "sap-icon://arrow-left" },
          onPress: () => () => handleDialogIndex("back")
        }),
        ft.Button("next", {
          properties: { icon: "sap-icon://arrow-right" },
          onPress: () => () => handleDialogIndex("next")
        }),
        ft.CheckBox("ignore", {
          properties: { text: "오늘 하루 보지 않기" },
          onChange: () => () => handleShowToday()
        })
      ]
    })
    .addField("title", {
      label: "제목",
      value: [currentNotice.title, "공지번호: ", currentNotice.id],
      component: [
        ft.Input("title", {
          properties: { editable: false, style: { width: "20rem" } }
        }),
        ft.Text("subRabel", {
          properties: { style: { width: "5rem", textAlign: "end" } }
        }),
        ft.Input("id", {
          properties: { editable: false, style: { width: "4rem" } }
        })
      ]
    })
    .addField("content", {
      label: "내용",
      value: currentNotice.content,
      component: ft.TextArea({
        properties: {
          editable: false,
          rows: 15,
          growing: true,
          growingMaxLines: 15
        }
      })
    });
  if (currentNotice.path) {
    dialogBuilder.addField("path", {
      label: "첨부 파일",
      value: currentNotice.filename,
      component: ft.Link({
        onPress: () => () => window.open(currentNotice.path)
      })
    });
  }

  const builder = formTable();

  builder.addDialog("noticeDialog", {
    title: "공지사항",
    // fullSize: false,
    hideBeginButton: true,
    isOpen: dialogIsOpen,
    headerImageUrl: logoUrl,
    onClose: () => handleClose,
    ftData: dialogBuilder.done()
  });

  return noticeList.length > 0 ? (
    <FormTableReact {...props} fn={{}} data={builder.done()}></FormTableReact>
  ) : null;
}

function mapStateToProps(state) {
  return {
    ignoreNotice: state.ui5.ignoreNotice,
    noticeList: state.ui5.noticeList || [],
    currentUser: state.user.currentUser
  };
}

export default connect(mapStateToProps)(NoticeDialog);

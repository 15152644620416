import {
  extractArgs,
  isBound,
  removeUndefinedKeys
} from "@bsgp/form-and-table/lib/functions";

function getComponentName({ elName, fieldComponent }) {
  return [elName, fieldComponent.name, fieldComponent.type]
    .filter(Boolean)
    .join("-");
}

function isDataBinding(text) {
  if (!text || typeof text !== "string") return false;

  // Check for simple key pattern like "{key}"
  const simpleKeyMatch = text.match(/^\{(\w+)\}$/);
  if (simpleKeyMatch) return true;

  // Check for template literal pattern includes {= ${key}}
  const templateLiteralMatch = text.match(/^\{=(.*\$\{(\w+)\}.*)\}$/);
  if (templateLiteralMatch) return true;

  return false;
}

function getValueFromDataBindingFormat(text, targetData?: object) {
  // if text is not a string, return text
  if (!text || typeof text !== "string") return text;

  // remove blanks from pattern
  const trimmedText = text.replace(/\s/g, "");

  // Check for simple key pattern like "{key}"
  const simpleKeyMatch = trimmedText.match(/^\{(\w+)\}$/);
  if (simpleKeyMatch) {
    const key = simpleKeyMatch[1];
    return targetData ? targetData[key] : key;
  }

  // Check for template literal pattern includes {= ${key}}
  // ex) "{= ${key}}", "{= !!${key}}", "{= !${key}}", "{= ${key}.length > 0}"
  const templateLiteralMatch = trimmedText.match(/^\{=(.*\$\{(\w+)\}.*)\}$/);
  if (templateLiteralMatch) {
    // divide {= ${key}} into two parts - expression and data
    const expressionText = templateLiteralMatch[1];
    const key = templateLiteralMatch[2];
    const data = targetData ? targetData[key] : key;
    const changedExpression = expressionText.replace(/\$\{.*\}/g, "param");

    // implement expression code with data
    const expression = new Function("param", `return ${changedExpression}`);
    return expression(data);
  }

  // If pattern is unrecognized, return original text/value
  return targetData ? targetData[text] : text;
}

function removeDataBinding(options) {
  if (!options) return {};
  const newOptions = {};
  for (const [key, value] of Object.entries(options)) {
    newOptions[key] = getValueFromDataBindingFormat(value);
  }
  return newOptions;
}

function getNormalizedString(value) {
  if (!value) return "";
  let stringValue = value;
  if (typeof value !== "string") {
    if (typeof value === "number") {
      stringValue = value.toString();
    } else {
      return "";
    }
  }
  return stringValue.toLowerCase().trim();
}

function handleFilter({ targetArray, filteringValue }) {
  return targetArray.filter(row => {
    return Object.values(row).some(rowValue => {
      const comparingVal = getNormalizedString(filteringValue);
      if (!comparingVal) return true;
      const targetVal = getNormalizedString(rowValue);
      if (!targetVal) return false;
      return targetVal.includes(comparingVal);
    });
  });
}

function getStyleCodeFromProperties({ properties, props }) {
  return removeUndefinedKeys({
    ...(properties.style || {}),
    ...(props.style || {}),
    ...(properties.visible === false ? { display: "none" } : {}),
    ...(properties.hAlign
      ? { textAlign: properties.hAlign.toLowerCase() }
      : {}),
    ...(properties.width ? { width: properties.width } : {}),
    ...(properties.height ? { height: properties.height } : {}),
    ...(properties.textAlign ? { textAlign: properties.textAlign } : {})
  });
}

export {
  extractArgs,
  isBound,
  getComponentName,
  getValueFromDataBindingFormat,
  removeDataBinding,
  getNormalizedString,
  handleFilter,
  getStyleCodeFromProperties,
  isDataBinding
};

import React, { ReactNode } from "react";
import { TabContainer, Tab } from "@/components";
import { TabContainerBackgroundDesign } from "@ui5/webcomponents-react";
import clsx from "clsx";
import { ICommonTabObject } from "@/types/common";

interface BaseIteratorProps<T> {
  items: T[];
  itemNameList: string[];
  wrapItems?: boolean;
  contextProvider?: React.ComponentType<{ children: ReactNode }>;
  tabContainerProps?: {
    className?: string;
    style?: React.CSSProperties;
    [key: string]: any;
  };
  children: (item: T, key: string) => ReactNode;
  tabProps?: (item: T) => object;
}

function BaseIterator<T extends ICommonTabObject>({
  items,
  itemNameList,
  wrapItems,
  contextProvider: ContextProvider,
  tabContainerProps = {
    className: ""
  },
  children,
  tabProps = () => ({})
}: BaseIteratorProps<T>) {
  const { className, ...tabContainerRest } = tabContainerProps;
  const Wrapper = ContextProvider || React.Fragment;

  return wrapItems ? (
    <TabContainer
      fixed={true}
      onTabSelect={oEvent => {
        const selectedIndex = oEvent.detail.tabIndex;
        const selectHandler =
          items[selectedIndex].onSelect || items[selectedIndex].onSelectTab;
        if (selectHandler) {
          const selectedKey = itemNameList[selectedIndex];
          selectHandler({ tabKey: selectedKey });
        }
      }}
      contentBackgroundDesign={TabContainerBackgroundDesign.Transparent}
      className={clsx("noBottomLine", className)}
      {...tabContainerRest}
    >
      {items.map((item, index) => {
        const key = itemNameList[index];

        return (
          <Tab
            key={key}
            text={item.title}
            selected={item.selected}
            {...tabProps(item)}
          >
            <Wrapper>{children(item, key)}</Wrapper>
          </Tab>
        );
      })}
    </TabContainer>
  ) : (
    <Wrapper>
      {items.map((item, index) => children(item, itemNameList[index]))}
    </Wrapper>
  );
}

export default BaseIterator;

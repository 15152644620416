import React from "react";
import { IConstructorProps } from "@/types/common";
import CodeEditorIterator from "./Iterator";
import BaseConstructor from "@/builders/common/BaseConstructor";

const CodeEditorConstructor = (props: IConstructorProps) => (
  <BaseConstructor
    {...props}
    dataKey="codeeditor"
    dataProcessor={data => {
      return data.hasOwnProperty("value") || data.hasOwnProperty("properties")
        ? { codeeditor: data.codeeditor }
        : data.codeeditor;
    }}
  >
    {renderProps => <CodeEditorIterator {...renderProps} />}
  </BaseConstructor>
);

export default CodeEditorConstructor;
